// import logo from './logo.svg';
// import './App.css';
// import { BrowserRouter, Router , Switch, Route, Routes } from 'react-router-dom';
// import { useState, useEffect } from 'react';
// import Header from './Components/Header/Header'
// import Homepage from './Components/HomePage/Homepage';
// import Footer from './Components/Footer/Footer';
// import Contact from './Components/Contact/Contact';
// import About from './Components/About/About';
// import Service from './Components/Service/Service';
// import Product from './Components/Product/Product';
// // import Loader from './Components/Loader/Loader';
// import ScrollToTop from './Components/ScrollToTop';
// import Privacy from './Components/Privacy/Privacy';
// // import LoadingGIF from '../public/assets/images/loader/lg-unscreen.gif'
// // import { Loader } from 'rsuite';
// // import { Loader } from 'rsuite';
// // import 'rsuite/dist/styles/rsuite-default.css';



// function App() {
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     setLoading(true);
//     setTimeout(() => {
//       setLoading(false);
//     }, 2000);
//   }, []);

  
//   return (
//     <div className="App">
 

//   {loading ? (


//     <div className="loader-container">
//     <div className="spinner"></div>
//   </div>

// ) : (
//   <>
  

//      <ScrollToTop />
//     <Header/>
//     <Routes>
  
//           <Route path="/" exact element={<Homepage />} />
//           <Route path="about" element={<About />} />
//           <Route path="service" element={<Service />} />
//           <Route path="product" element={<Product />} />
//           <Route path="contact" element={<Contact />} />
//           <Route path="privacy" element={<Privacy />} />

//     </Routes>
//     <Footer/>


//   </>

//   )}
//   </div>

    
//   );
// }


// export default App;

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Router , Switch, Route, Routes, Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Header from './Components/Header/Header'
import Homepage from './Components/HomePage/Homepage';
import Footer from './Components/Footer/Footer';
import Contact from './Components/Contact/Contact';
import About from './Components/About/About';
import Service from './Components/Service/Service';
import Product from './Components/Product/Product';
import ScrollToTop from './Components/ScrollToTop';
import Privacy from './Components/Privacy/Privacy';

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [location.pathname]); // Trigger loading whenever the location (route) changes
  
  {/*
  <div className="loader-container">
  <div className="spinner"></div>
  </div>
*/}
  return (
    <div className="App">
      {loading ? (

        <div class="main-page">
                  <div class="loader">
                      <div class="spin-blend"></div>
                      <div class="spin-blend"></div>
                      <div class="spin-blend"></div>
                      <div class="spin-blend"></div>
                      <div class="spin-blend"></div>
                  </div>
                 
              </div>

      ) : (
        <>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="about" element={<About />} />
            <Route path="service" element={<Service />} />
            <Route path="product" element={<Product />} />
            <Route path="contact" element={<Contact />} />
            <Route path="privacy" element={<Privacy />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;

